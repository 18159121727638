@font-face {
  font-family: font;
  src: url("./assets/fonts/get_schwifty.ttf");
}

* {
  outline: none;
}

body {
  background-color: #000;
  color: #fff;
  font-family: font;
}

.App {
  height: 100vh;
  overflow: hidden;
  background-color: #000;
  position: relative;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 30px 15px;
  background: #6dd814;
  font-size: 20px;
}

.banner {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100px;
  max-width: 500px;
}

.App-video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.App-content {
  position: absolute;
  z-index: 100;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}

.row,
.row-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.row-title {
  text-align: center;
  margin-bottom: 80px;
}

.row-title h1 {
  font-size: 70px;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #f00;
  -webkit-text-fill-color: #f00; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #ffd600;
}

.row-title h2 {
  margin-top: 10px;
  font-size: 50px;
  color: #ffd600;
  -webkit-text-fill-color: #ffd600; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #ff0000;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.row-ca {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  font-size: 22px;
}

.row-ca .email {
  color: #000;
  margin-top: 10px;
  display: block;
}

.row {
  /* margin-bottom: 50px; */
}

.row-center {
  justify-content: center;
}

img {
  width: 100%;
}

.row-btn {
  /* margin-bottom: 100px; */
}

.row-2 {
  margin-top: 120px;
}

.header {
  top: 30px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.header-logo {
  width: 130px;
}

.social {
  display: inline-block;
  width: 55px;
  height: 55px;
}

.social img {
  width: 100%;
}

.btn {
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 55px;
  color: #000;
  /* background-color: #fff; */
  /* border-radius: 8px; */
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
  /* border: 1px solid #37ace1; */
  /* font-size: 25px; */
  transition: all 0.3s linear;
}

.btn:hover {
  opacity: 0.8;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
  max-width: 700px;
  min-height: 200px;
  margin: 0 auto;
  text-align: center;
  font-size: 25px;
  background-color: #e5083c;
  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal button {
  background-color: #fff;
  border: 0;
  outline: none;
  padding: 10px 50px;
  border-radius: 5px;
  font-family: font, sans-serif;
}

.modal span {
  display: inline-block;
  font-size: 40px;
  margin-top: 30px;
}

.modal-btn {
  margin-top: 30px;
  display: flex;
  gap: 30px;
}

.modal-wrapper {
  background-color: #000000c9;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
}

.modal-wrapper.hidden {
  display: none;
}

.blink-soft {
  animation: blinker 1.5s linear infinite;
}

.blink-soft-2 {
  animation: blinker 1s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@media screen and (max-width: 600px) {
  .modal {
    padding: 30px 50px;
  }
  /* .App-content {
    bottom: unset;
    top: 50px;
  } */
  .row-2 {
    margin-top: 50px;
  }
  .row-btn {
    margin-bottom: 50px;
  }

  .row-title h1 {
    font-size: 50px;
  }
  .row-title h2 {
    font-size: 40px;
  }
  .btn {
    font-size: 18px;
  }
}
